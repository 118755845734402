<template>
  <div>
    <v-form ref="form2" v-model="valid" lazy-validation>
      <v-dialog v-model="showForm" max-width="800px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }} </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <currencyinput
                    :label="'Montant ' + '(' + editedItem.currency + ')'"
                    v-model="editedItem.montant"
                    :rules="[(v) => !!v || v > 0 || 'Montant obligatoire']"
                    :readonly="!modify"
                    :key="mk"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  v-if="editedItem.currency_id && editedItem.currency_id != 114"
                >
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    outlined
                    dense
                    v-model.number="editedItem.taux_change"
                    label="Taux de change"
                    :rules="[
                      (v) => !!v || v > 0 || 'Taux de change obligatoire',
                    ]"
                    :readonly="editedItem.statut_id > 1"
                    @focus="$event.target.select()"
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <datepicker
                    :label="'Date Echéance'"
                    v-model="editedItem.date_ech"
                    :value="editedItem.date_ech"
                    :edit="modify"
                    :clearable="false"
                    :date_min="$store.state.today"
                    :outlined="true"
                    :rules="[(v) => !!v || 'Date obligatoire']"
                    :key="cs"
                  ></datepicker>
                </v-col>
                <v-col cols="12" sm="3" md="3" v-if="editedItem.statut_id == 1">
                  <datepicker
                    :label="'Nouvelle Date'"
                    v-model="editedItem.new_date_ech"
                    :value="editedItem.new_date_ech"
                    :edit="editedItem.statut_id == 1"
                    :clearable="false"
                    :date_min="editedItem.date_ech"
                    :outlined="true"
                    :rules="[
                      (v) => !!v || !toreport || 'Nouvelle Date obligatoire',
                    ]"
                    :key="cs"
                  ></datepicker>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-if="editedItem.statut_id == 1"
                >
                  <v-card>
                    <v-card-title>Exécution</v-card-title>
                    <v-card-text>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="editedItem.user_id"
                              :items="users_list"
                              item-text="name"
                              item-value="id"
                              label="Agent"
                              :rules="[
                                (v) => !!v || toreport || 'Agent obligatoire',
                              ]"
                              dense
                              outlined
                              :readonly="editedItem.statut_id > 1"
                            >
                              <template v-slot:item="{ item }">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ item.name }}
                                  </v-list-item-title>

                                  <v-list-item-subtitle>
                                    {{
                                      "Solde " +
                                      (item.solde
                                        ? numberWithSpace(
                                            parseFloat(item.solde).toFixed(2)
                                          )
                                        : 0.0)
                                    }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <datepicker
                              label="Date Max"
                              v-model="editedItem.date_limite"
                              :value="editedItem.date_limite"
                              :edit="editedItem.statut_id == 1"
                              :date_min="$store.state.today"
                              :clearable="false"
                              :outlined="true"
                              :key="cs"
                              dense
                            ></datepicker>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-checkbox
                              v-model="editedItem.email_agent"
                              label="E-mail Agent"
                              :readonly="editedItem.statut_id > 1"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-checkbox
                              v-model="editedItem.email_opr"
                              label="E-mail Operateur"
                              :readonly="editedItem.statut_id > 1"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    outlined
                    type="text"
                    label="Comment"
                    v-model="editedItem.comment"
                    :readonly="!modify"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              :disabled="!valid"
              @click="annuler"
              v-if="editedItem.statut_id == 1"
            >
              Annuler
            </v-btn>
            <v-btn
              color="blue darken-1"
              :disabled="!valid"
              @click="reporter"
              v-if="editedItem.statut_id == 1"
            >
              Reporter
            </v-btn>
            <v-btn
              color="blue darken-1"
              :disabled="!valid"
              @click="executer"
              v-if="editedItem.statut_id == 1"
            >
              Executer
            </v-btn>
            <v-btn
              color="blue darken-1"
              :disabled="!valid"
              @click="save"
              v-if="modify"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" @click="close"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </div>
</template>

<script>
import CREATE_ECHEANCE from "../graphql/Credits/CREATE_ECHEANCE.gql";
import UPDATE_ECHEANCE from "../graphql/Credits/UPDATE_ECHEANCE.gql";

export default {
  name: "echeance",
  components: {
    datepicker: () => import("./DatePicker.vue"),
    currencyinput: () => import("./CurrencyInput.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    item: Object,
    showForm: Boolean,
    credit: Object,
    list: Array,
    users: Array,
    modify: Boolean,
  },

  data: () => ({
    valid: true,
    cs: 200,
    mk: 300,
    editedItem: {},
    toreport: false,
    defaultItem: {
      id: -1,
    },
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    formTitle() {
      return this.editedItem.id == -1
        ? "Nouvelle Echéance"
        : "Modifier l'echéance";
    },
    users_list() {
      let l = [];
      if (this.users)
        this.users.forEach((element) => {
          if (element.autorisations) {
            if (element.autorisations.split(",").includes("03003"))
              l.push(element);
          } else if (element.profile.autorisations) {
            if (element.profile.autorisations.split(",").includes("03003"))
              l.push(element);
          }
        });
      console.log(l);
      return l;
    },
  },

  watch: {},

  created() {},
  mounted() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.cs++;
        this.mk++;
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.currency_id = this.credit.currency_id;
        this.editedItem.currency = this.credit.currency;
        this.editedItem.taux_change = this.credit.taux_change;
        this.$refs.form2.resetValidation();
      }
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async annuler() {
      this.toreport = false;
      if (this.$refs.form2.validate()) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Annuler cet echeance?",
            { color: "orange darken-3" }
          )
        ) {
          let v = {
            echeance: {
              id: this.editedItem.id,
              statut_id: 3,
              comment: this.editedItem.comment,
            },
          };
          let r = await this.maj(UPDATE_ECHEANCE, v);
          if (r) {
            this.editedItem.statut_id = 3;
            this.$store.dispatch("alert", true);
            this.$store.dispatch("Changed", true);
            this.close();
          }
        }
      }
    },
    async reporter() {
      this.toreport = true;
      if (this.$refs.form2.validate()) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Reporter cet echeance?",
            { color: "orange darken-3" }
          )
        ) {
          let v = {
            echeance: {
              id: this.editedItem.id,
              statut_id: 1,
              date_ech: this.editedItem.new_date_ech,
              user_id: this.editedItem.user_id,
              taux_change: parseFloat(this.editedItem.taux_change),
              date_limite: this.editedItem.date_limite,
              email_agent: this.editedItem.email_agent ? 1 : 0,
              email_opr: this.editedItem.email_opr ? 1 : 0,
              comment: this.editedItem.comment,
            },
          };
          let r = await this.maj(UPDATE_ECHEANCE, v);
          if (r) {
            this.editedItem.statut_id = 1;
            this.editedItem.date_ech = this.editedItem.new_date_ech;
            this.editedItem.new_date_ech = null;
            this.cs++;
            this.$store.dispatch("alert", true);
            this.$store.dispatch("Changed", true);
          }
        }
        this.toreport = false;
      }
    },
    async executer() {
      this.toreport = false;
      if (this.$refs.form2.validate()) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Executer cet echeance?",
            { color: "orange darken-3" }
          )
        ) {
          let v = {
            echeance: {
              id: this.editedItem.id,
              statut_id: 2,
              user_id: this.editedItem.user_id,
              taux_change: parseFloat(this.editedItem.taux_change),
              date_limite: this.editedItem.date_limite,
              email_agent: this.editedItem.email_agent ? 1 : 0,
              email_opr: this.editedItem.email_opr ? 1 : 0,
              comment: this.editedItem.comment,
            },
          };
          let r = await this.maj(UPDATE_ECHEANCE, v);
          if (r) {
            this.editedItem.statut_id = 2;
            this.$store.dispatch("alert", true);
            this.$store.dispatch("Changed", true);
            this.close();
          }
        }
      }
    },
    numberWithSpace: function (x) {
      return x != null
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "0";
    },
    async save() {
      if (this.$refs.form2.validate()) {
        let ok = true;
        let s = 0;
        this.list.forEach((element) => {
          if (element.id != this.editedItem.id) {
            s = s + parseFloat(element.montant);
          }
        });
        if (
          s + parseFloat(this.editedItem.montant) >
          parseFloat(this.credit.montant)
        ) {
          ok = false;
          this.snackbar_text =
            "Montant ou cumul des montants supperieur au montant du credit - erreur";
          this.snackbar_color = "error";
          this.snackbar = true;
        }
        if (ok) {
          if (this.editedItem.id > -1) {
            let v = {
              echeance: {
                id: this.editedItem.id,
                montant: parseFloat(this.editedItem.montant),
                taux_change: parseFloat(this.editedItem.taux_change),
                date_ech: this.editedItem.date_ech,
                user_id: this.editedItem.user_id,
                date_limite: this.editedItem.date_limite,
                comment: this.editedItem.comment,
              },
            };
            let r = await this.maj(UPDATE_ECHEANCE, v);
            if (r) {
              this.list.splice(this.editedItem.index, 1, this.editedItem);
            }
          } else {
            this.editedItem.statut_id = 0;
            this.editedItem.status_name = "Att. Val.";
            this.editedItem.status_color = "gray";
            let v = {
              echeance: {
                credit_id: this.credit.id,
                statut_id: this.editedItem.statut_id,
                user_id: this.editedItem.user_id,
                date_limite: this.editedItem.date_limite,
                montant: parseFloat(this.editedItem.montant),
                taux_change: parseFloat(this.editedItem.taux_change),
                date_ech: this.editedItem.date_ech,
                comment: this.editedItem.comment,
              },
            };
            let r = await this.maj(CREATE_ECHEANCE, v);
            if (r) {
              this.editedItem.id = r.createEcheance.id;
              this.list.push(this.editedItem);
            }
          }
          this.close();
        }
      }
    },
  },
};
</script>
